import styled from "@emotion/styled";
import Background from "./assets/background.jpg";

export const PageWrapper = styled.div`
  color: #333;
  margin: 0;
  padding: 0;
  width: 100%;
  gap: 50px;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
`;

export const MainBanner = styled.section`
  padding: 3rem 2rem;
  text-align: center;
  background-image: url(${Background});
  background-size: cover;
  background-position: center;

  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  p {
    max-width: 800px;
    margin: 0.5rem auto;
    line-height: 1.5;
  }
`;

export const StyledMainBanner = styled(MainBanner)`
  padding-top: 100px;
  padding-bottom: 100px;
`;

export const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: white;
  padding: 2rem;
  border-radius: 15px;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  padding-top: 200px;
  margin-top: 150px;
`;

export const BannerImage = styled.img`
  position: absolute;
  top: -180px;
`;

export const BracketInfo = styled.div`
  background-color: #ffdfb3;
  padding: 30px 80px;
  border-radius: 8px;
  width: 100%;
`;

export const BracketInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  text-align: left;
  gap: 5px;
`;

export const HowItWorks = styled.section`
  padding: 2rem;
  background-color: #ffffff;
  text-align: center;

  h2 {
    font-size: 1.75rem;
    margin-bottom: 1rem;
  }

  .steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: center;
    }

    .step {
      height: 300px;
      border-radius: 8px;
      padding: 1rem;
      max-width: 250px;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      align-items: center;
      justify-content: flex-start;
      margin: 0 1rem;

      h3 {
        font-size: 18px;
        white-space: nowrap;
      }

      .step-number {
        background-color: #fb9246;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-weight: bold;
        font-size: 1.25rem;
      }
    }
  }
`;

export const ScheduleSection = styled.section`
  padding: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5;

  .schedule {
    margin-bottom: 20px;
  }

  h2 {
    text-align: left;
    margin-bottom: 1.5rem;
  }

  strong {
    display: block;
    margin: 1rem;
    font-weight: bold;
  }

  img {
    height: 590px;
  }

  ul {
    max-width: 600px;
    margin-left: 30px;
    padding: 0;
  }
`;

export const ScheduleContent = styled.div`
  flex: 1;
`;

export const MonstersShowcase = styled.section`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1.5rem;

  h2 {
    margin-bottom: 1rem;
  }

  .monsters {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: stretch;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    .monster-card {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      background-color: #ffdfb3;
      padding: 1rem;
      border-radius: 8px;
      text-align: center;

      .monster-img {
        display: flex;
        flex: 1;
      }

      img {
        align-self: center;
        height: 250px;
        margin-bottom: 0.5rem;
      }
    }

    .subtitle-1 {
      font-weight: bold;
    }
  }
`;

export const FaqImageContainer = styled.div`
  flex: 1;
  align-self: flex-end;
  text-align: right;
`;

export const ActivityHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
