import React from "react";

import { Stack } from "@src/components/extended";
import MojoMadness from "./assets/mojo-madness-no-mojo.png";
import Activities1 from "./assets/Activities-1.png";
import Activities2 from "./assets/Activities-2.png";
import Activities3 from "./assets/Activities-3.png";
import FAQ from "./assets/FAQ.png";
import Container from "@src/components/Container";
import { FaqList } from "@src/ui/FAQ";
import { Body1Bold, Body1SemiBold, Body2, Display1, Headline2 } from "@src/styles/piltri";
import { map } from "lodash";
import { logEvent } from "@src/utils/logClient";
import {
  PageWrapper,
  HowItWorks,
  ScheduleSection,
  ScheduleContent,
  MonstersShowcase,
  ActivityHeader,
  FaqImageContainer,
  StyledMainBanner,
} from "./styles";
import { MatchesSlider } from "./MatchesSlider";
import { rounds } from "./data";

const MonsterMarchMadness: React.FC = () => {
  return (
    <PageWrapper>
      <StyledMainBanner>
        <img
          src={MojoMadness}
          alt="Mojo Madness"
          css={{ width: 300, marginBottom: -50, position: "relative", zIndex: 2 }}
        />
        <MatchesSlider rounds={rounds} />
      </StyledMainBanner>

      {/* SCHEDULE */}
      <Container>
        <ScheduleSection id="schedule">
          <ScheduleContent>
            <Display1 css={{ textAlign: "center" }}>Schedule</Display1>
            <Stack gap={24} flexDirection={["column", "row"]} justifyContent="space-around" width="100%">
              <div className="schedule">
                <Headline2>Sweet Sixteen</Headline2>
                <ul>
                  <Body2 as="li">March 18th: Game #1</Body2>
                  <Body2 as="li">Wednesday, March 19th: Game #2</Body2>
                  <Body2 as="li">Thursday, March 20th: Game #3</Body2>
                  <Body2 as="li">Friday, March 21st: Game #4</Body2>
                  <Body2 as="li">Tuesday, March 25th: Game #5</Body2>
                  <Body2 as="li">Wednesday, March 26th: Game #6</Body2>
                  <Body2 as="li">Thursday, March 27th: Game #7</Body2>
                  <Body2 as="li">Friday, March 28th: Game #8</Body2>
                </ul>
              </div>
              <div className="schedule">
                <Headline2>Elite Eight</Headline2>
                <ul>
                  <Body2 as="li">Tuesday, April 1st: Game #9</Body2>
                  <Body2 as="li">Wednesday, April 2nd: Game #10</Body2>
                  <Body2 as="li">Thursday, April 3rd: Game #11</Body2>
                  <Body2 as="li">Friday, April 4th: Game #12</Body2>
                  <Body2 as="li">Final Four Saturday, April 5th: Game #13</Body2>
                  <Body2 as="li">Sunday, April 6th: Game #14</Body2>
                </ul>

                <Headline2>Championship</Headline2>
                <ul>
                  <Body2 as="li">Monday, April 7th: Game #15</Body2>
                </ul>
              </div>
            </Stack>
          </ScheduleContent>
        </ScheduleSection>
      </Container>

      {/* MONSTERS SHOWCASE */}
      <Container>
        <MonstersShowcase>
          <ActivityHeader>
            <Display1>Get your activities</Display1>
            <Body2 style={{ textAlign: "center", maxWidth: "600px" }}>
              Download weekly movement-inspired Fun Friday activities —one for each week of the tournament—to get
              students moving and engaged.
            </Body2>
          </ActivityHeader>
          <div className="monsters">
            {map(
              [
                [
                  Activities1,
                  "Week 1",
                  "Dribble and Spell",
                  "https://static.classdojo.com/img/2025/03/MojoMadness-Dribble_and_Spell.pdf",
                ],
                [
                  Activities2,
                  "Week 2",
                  "Simon Says",
                  "https://static.classdojo.com/img/2025/03/MojoMadness-Simon_Says.pdf",
                ],
                [
                  Activities3,
                  "Week 3",
                  "Hot Potato",
                  "https://static.classdojo.com/img/2025/03/MojoMadness-Hot_Potato.pdf",
                ],
              ],
              ([img, subtitle1, subtitle2, pdfUrl], index) => (
                <div className="monster-card" key={index}>
                  <a
                    href={pdfUrl}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() =>
                      logEvent({
                        eventName: "web.mojo-madness.download-activity",
                        metadata: { week: index + 1 },
                      })
                    }
                  >
                    <div className="monster-img">
                      <img src={img} alt="Activity" />
                    </div>
                    <Body2>
                      <Body1SemiBold>{subtitle1}</Body1SemiBold>
                      <Body2>{subtitle2}</Body2>
                    </Body2>
                  </a>
                </div>
              ),
            )}
          </div>
        </MonstersShowcase>
      </Container>

      {/* FAQ */}
      <Container>
        <Stack flexDirection={["column", "row"]} gap={24}>
          <div>
            <Display1>FAQs</Display1>
            <FaqList
              faqs={[
                {
                  question: "How will the winner be chosen?",
                  answer:
                    "Locking in your bracket by March 17 (before the first matchup the next day!) ensures that each teacher and their class have an equal chance of winning. The winner must not only predict the overall Mojo Madness Champion but also submit a perfect bracket—meaning they must correctly choose the outcome of all 15 games.If no perfect bracket is submitted, the ClassDojo team will select the teachers with the closest brackets and hold a tie-breaker to determine the overall winner.",
                },
                {
                  question: "How can we follow along with the games?",
                  answer:
                    "The results of the “games” — aka 1v1 matchups between our characters will be released according to the schedule at the top of this site! Scroll to the top to view previous results & the results of the match of the day. The championship game will take place on April 7th.",
                },
                {
                  question: "Do we need to do the movement activities to enter the contest?",
                  answer:
                    "Nope! The activities are just a fun way to keep your class engaged during the tournament, especially as everyone gets antsy before spring break. They’re a great way to get students up and moving while also enhancing their learning environment.",
                },
                {
                  question: "What is Total Physical Response (TPR)?",
                  answer:
                    "Total Physical Response (TPR) is a language-learning and teaching method developed by Dr. James Asher in the 1960s. It is based on the idea that students learn best when they physically respond to verbal commands, engaging their bodies in the learning process. This method is particularly effective for young learners, English Language Learners (ELLs), and kinesthetic learners who benefit from movement-based learning. All three of our Fun Friday TPR activities are designed to boost engagement, reduce classroom stress, and integrate academics with movement.",
                },
                {
                  question: "What are the terms and conditions?",
                  answer:
                    "<a href='/mojo-madness/terms-and-conditions' target='_blank'>Read the full terms & conditions here!</a>",
                },
              ]}
            />
          </div>
          <FaqImageContainer>
            <img src={FAQ} alt="faq" width={550} />
          </FaqImageContainer>
        </Stack>
      </Container>
    </PageWrapper>
  );
};

export default MonsterMarchMadness;
