import moment, { Moment } from "moment";
import Alex from "@src/assets/images/march-madness/Alex.png";
import BB from "@src/assets/images/march-madness/bb.png";
import Bear from "@src/assets/images/march-madness/Bear.png";
import Bruce from "@src/assets/images/march-madness/Bruce.png";
import Chaos from "@src/assets/images/march-madness/Chaos.png";
import Christophe from "@src/assets/images/march-madness/Christophe.png";
import Danni from "@src/assets/images/march-madness/Danni.png";
import Gabi from "@src/assets/images/march-madness/Gabi.png";
import Gumi from "@src/assets/images/march-madness/Gumi.png";
import Harry from "@src/assets/images/march-madness/Harry.png";
import JennaJamie from "@src/assets/images/march-madness/JennaJamie.png";
import Katie from "@src/assets/images/march-madness/Katie.png";
import Manman from "@src/assets/images/march-madness/Manman.png";
import Pinky from "@src/assets/images/march-madness/Pinky.png";
import Trooper from "@src/assets/images/march-madness/Trooper.png";
import Violet from "@src/assets/images/march-madness/Violet.png";

export interface Team {
  image: string;
  name: string;
}

export interface Match {
  team1: Team;
  team2: Team;
  date: Moment;
  winner?: Team;
}

const createTeam = (name: string, image: string): Team => ({
  name,
  image,
});

const teams = {
  Alex: createTeam("Alex", Alex),
  BB: createTeam("BB", BB),
  Bear: createTeam("Bear", Bear),
  Bruce: createTeam("Bruce", Bruce),
  Chaos: createTeam("Chaos", Chaos),
  Christophe: createTeam("Christophe", Christophe),
  Danni: createTeam("Danni", Danni),
  Gabi: createTeam("Gabi", Gabi),
  Gumi: createTeam("Gumi", Gumi),
  Harry: createTeam("Harry", Harry),
  JennaJamie: createTeam("Jenna & Jamie", JennaJamie),
  Katie: createTeam("Katie", Katie),
  Manman: createTeam("Manman", Manman),
  Pinky: createTeam("Pinky", Pinky),
  Trooper: createTeam("Trooper", Trooper),
  Violet: createTeam("Violet", Violet),
};

type TeamName = keyof typeof teams;

const createMatch = <A extends TeamName, B extends TeamName>(
  team1: A,
  team2: B,
  date: string,
  winner: A | B,
): Match => ({
  team1: teams[team1],
  team2: teams[team2],
  date: moment(`2025-${date}`),
  winner: winner ? teams[winner] : undefined,
});

export type Rounds = {
  label: string;
  matches: Match[];
};

export const rounds: Rounds[] = [
  {
    label: "Sweet Sixteen",
    matches: [
      createMatch("Bear", "Christophe", "03-18", "Christophe"),
      createMatch("Danni", "JennaJamie", "03-19", "JennaJamie"),
      createMatch("Alex", "Harry", "03-20", "Harry"),
      createMatch("Gumi", "Katie", "03-21", "Gumi"),
      createMatch("Trooper", "Gabi", "03-25", "Gabi"),
      createMatch("Chaos", "Violet", "03-26", "Chaos"),
      createMatch("BB", "Pinky", "03-27", "Pinky"),
      createMatch("Manman", "Bruce", "03-28", "Manman"),
    ],
  },
  {
    label: "Elite Eight",
    matches: [
      createMatch("Christophe", "JennaJamie", "04-01", "JennaJamie"),
      createMatch("Harry", "Gumi", "04-02", "Harry"),
      createMatch("Gabi", "Chaos", "04-03", "Chaos"),
      createMatch("Pinky", "Manman", "04-04", "Manman"),
    ],
  },
  {
    label: "Final Four",
    matches: [
      createMatch("JennaJamie", "Harry", "04-05", "JennaJamie"),
      createMatch("Chaos", "Manman", "04-06", "Chaos"),
      createMatch("JennaJamie", "Chaos", "04-07", "Chaos"),
    ],
  },
];
