import React, { useEffect } from "react";

import SEO from "@src/components/SEO";
import SeoImage from "./assets/seo-image.jpg";
import { logEvent } from "@src/utils/logClient";
import MonsterMarchMadness from "@src/components/partials/mojo-madness/page";

const MonsterMarchMadnessPage: React.FC = () => {
  useEffect(() => {
    logEvent({ eventName: "web.mojo-madness.screen_view" });
  }, []);

  return (
    <>
      <SEO
        title="Pick the winner of Mojo Madness to win BIG"
        description="Your favorite ClassDojo characters are facing off in a March Madness-style, three-week tournament!"
        image={SeoImage}
      />
      <MonsterMarchMadness />
    </>
  );
};

export default MonsterMarchMadnessPage;
