import styled from "@emotion/styled";
import { Global, css } from "@emotion/react";
import { Flex } from "@src/components/Boxes";
import { Column, Stack } from "@src/components/extended";
import React, { useState } from "react";
import { Rounds, Team } from "./data";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Button from "@src/ui/Button";
import CommonModal from "@src/components/modals/CommonModal";
import { Body1SemiBold, Display1, Headline1 } from "@src/styles/piltri";
import moment from "moment";
import { mediaQueriesMax } from "@src/styles/theme";

const globalStyles = css`
  .slick-prev:before,
  .slick-next:before {
    font-size: 50px;
  }
  .slick-dots li button:before {
    font-size: 25px;
  }
  .slick-dots {
    bottom: -50px;
  }
`;

const Conainer = styled(Flex)`
  border-radius: 30px;
  background: #fff;
  display: flex;
  padding: 60px 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 34px;

  ${mediaQueriesMax[0]} {
    padding: 30px 30px;
    .match {
      gap: 10px;
    }
    img {
      max-width: 150px;
    }

    button {
      min-width: 200px;
    }
  }
`;

// Custom arrow components are still used (content in pseudo-elements is now styled via global CSS)
const NextArrow = (props: any) => {
  const { className, style, onClick } = props;

  const keyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      onClick();
    }
  };

  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        right: "40px",
        zIndex: 2,
      }}
      onClick={onClick}
      onKeyDown={keyDownHandler}
      role="button"
      tabIndex={0}
    />
  );
};

const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;

  const keyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      onClick();
    }
  };

  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        left: "10px",
        zIndex: 2,
      }}
      onClick={onClick}
      onKeyDown={keyDownHandler}
      role="button"
      tabIndex={0}
    />
  );
};

interface MatchesProps {
  team1: Team;
  team2: Team;
  winner?: Team;
  date: string;
  gameNumber: string;
  gameTitle: string;
  active: boolean;
}

const Matches = (props: MatchesProps) => {
  const [showWinnder, setShowWinner] = useState(false);
  const { team1, team2, date, gameNumber, gameTitle, winner } = props;

  const handleShowWinner = () => setShowWinner(true);
  const closeModal = () => setShowWinner(false);

  return (
    <>
      {showWinnder && (
        <CommonModal noHeader closeModal={closeModal}>
          <img src={winner.image} alt="winner" />
          <Headline1>{winner.name}</Headline1>
        </CommonModal>
      )}
      <Flex alignItems="center" justifyContent="center">
        <Conainer>
          <Stack flexDirection={["column", "row"]} gap={30} className="match">
            <Column alignItems={["center", "flex-start"]}>
              <img src={team1.image} alt={team1.name} />
            </Column>
            <Column flex={1}>
              <Headline1>{gameTitle}</Headline1>
              <Body1SemiBold>
                {date}: Game #{gameNumber}
              </Body1SemiBold>
              <Display1 css={{ color: "black" }}>
                <div>{team1.name}</div>
                <div>VS</div>
                <div>{team2.name}</div>
              </Display1>
            </Column>
            <Column alignItems={["center", "flex-end"]}>
              <img src={team2.image} alt={team2.name} />
            </Column>
          </Stack>
          <Button variant="secondary" onClick={handleShowWinner}>
            See who won! 🏀
          </Button>
        </Conainer>
      </Flex>
    </>
  );
};

interface MatchesSliderProps {
  rounds: Rounds[];
}
export const MatchesSlider = ({ rounds }: MatchesSliderProps) => {
  const now = moment();
  let gameNumber = 0;

  // Flatten all matches from rounds and sort them chronologically
  const allItems = rounds.flatMap((round, roundIndex) =>
    round.matches.map((match, matchIndex) => {
      gameNumber += 1;
      return {
        key: `${roundIndex}-${matchIndex}`,
        team1: match.team1,
        team2: match.team2,
        winner: match.winner,
        date: match.date, // Moment object
        gameNumber: String(gameNumber),
        gameTitle: round.label,
      };
    }),
  );
  allItems.sort((a, b) => a.date.diff(b.date));

  // Separate active matches from future ones
  const activeMatches = allItems.filter((item) => item.date.isSameOrBefore(now));
  const futureCount = allItems.filter((item) => item.date.isAfter(now)).length;

  const settings: Settings = {
    dots: true,
    infinite: false, // prevent looping
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: activeMatches.length - 1, // last active match is selected initially
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: (dots) => (
      <div style={{ padding: "10px" }}>
        <ul
          style={{
            margin: "0px",
            padding: "0",
            display: "flex",
            justifyContent: "center",
            listStyle: "none",
          }}
        >
          {dots}
          {Array.from({ length: futureCount }).map((_, i) => (
            <li key={`fake-${i}`} className="fake-dot" style={{ margin: "0 4px" }}>
              <button
                className="inactive-dot-button"
                style={{
                  border: "none",
                  background: "transparent",
                  cursor: "default",
                  opacity: 0.5,
                }}
                disabled
              >
                ●
              </button>
            </li>
          ))}
        </ul>
      </div>
    ),
    customPaging: (i) => (
      <button
        style={{
          border: "none",
          background: "transparent",
          cursor: "pointer",
        }}
      >
        ●
      </button>
    ),
  };

  const matchesEls = activeMatches.map(({ date, ...match }) => (
    <Matches key={match.key} {...match} date={date.format("MMM DD")} active={true} />
  ));

  return (
    <>
      <Global styles={globalStyles} />
      <Slider {...settings}>{matchesEls}</Slider>
    </>
  );
};
